<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Register v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <vuexy-logo />
                    <h2 class="brand-text text-primary ml-1">
                        {{ $t('appName') }}
                    </h2>
                </b-link>

                <b-card-title class="mb-1">
                    {{ $t('register.title') }}
                </b-card-title>
                <b-card-text class="mb-2">
                    {{ $t('register.description') }}
                </b-card-text>

                <!-- form -->
                <validation-observer ref="registerForm">
                    <b-form
                        class="auth-register-form mt-2"
                        @submit.prevent="validationForm"
                    >
                        <!-- username -->
                        <b-form-group
                            :label="$t('ui.username')"
                            label-for="username"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('ui.username')"
                                :rules="`required|min:${usernameOptions.minlength}|max:${usernameOptions.maxlength}|alpha`"
                            >
                                <b-form-input
                                    id="username"
                                    v-model="username"
                                    :state="errors.length > 0 ? false : null"
                                    name="register-username"
                                    placeholder="johndoe"
                                    :maxlength="usernameOptions.maxlength"
                                    :minlength="usernameOptions.minlength"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- email -->
                        <b-form-group :label="$t('ui.email')" label-for="email">
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('ui.email')"
                                :rules="`required|email|max:${emailOptions.maxlength}`"
                            >
                                <b-form-input
                                    id="email"
                                    v-model.trim="regEmail"
                                    :state="errors.length > 0 ? false : null"
                                    name="register-email"
                                    placeholder="john@example.com"
                                    :maxlength="emailOptions.maxlength"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group
                            :label="$t('ui.password')"
                            label-for="password"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('ui.password')"
                                :rules="`required|max:${passwordOptions.maxlength}`"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="password"
                                        :type="passwordFieldType"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        class="form-control-merge"
                                        name="register-password"
                                        placeholder="············"
                                        :maxlength="passwordOptions.maxlength"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="passwordToggleIcon"
                                            class="cursor-pointer"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- checkbox -->
                        <b-form-group>
                            <b-form-checkbox
                                id="register-privacy-policy"
                                v-model="status"
                                name="checkbox-1"
                            >
                                <i18n :tag="false" path="register.agreement">
                                    <template v-slot:action>
                                        <b-link>{{
                                            $t('register.terms')
                                        }}</b-link>
                                    </template>
                                </i18n>
                            </b-form-checkbox>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button variant="primary" block type="submit">
                            {{ $t('register.signUp') }}
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <span>{{ $t('register.oldUser') + ' ' }}</span>

                    <b-link :to="{ name: 'login' }">
                        <span>{{ $t('register.signIn') }}</span>
                    </b-link>
                </b-card-text>
            </b-card>
            <!-- /Register v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
} from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { required, email, min, max, alpha } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAuth from '@/views/pages/useAuth';

export default {
    components: {
        VuexyLogo,
        // BSV
        BCard,
        BLink,
        BCardTitle,
        BCardText,
        BForm,
        BButton,
        BFormInput,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        // validations
        ValidationProvider,
        ValidationObserver,
    },

    mixins: [togglePasswordVisibility],

    data() {
        return {
            regEmail: '',
            username: '',
            password: '',
            status: '',

            // validation rules
            required,
            email,
            min,
            max,
            alpha,
        };
    },

    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password'
                ? 'EyeIcon'
                : 'EyeOffIcon';
        },

        locale() {
            return this.$i18n.locale;
        },
    },

    watch: {
        locale() {
            this.$refs.registerForm.reset();
        },
    },

    methods: {
        validationForm() {
            this.$refs.registerForm.validate().then((success) => {
                if (success) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Form Submitted',
                            icon: 'EditIcon',
                            variant: 'success',
                        },
                    });
                }
            });
        },
    },

    setup() {
        const { usernameOptions, emailOptions, passwordOptions } = useAuth();

        return {
            usernameOptions,
            emailOptions,
            passwordOptions,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
